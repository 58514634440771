
  import { mapState, mapActions } from 'vuex'
  
  export default {
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapState(['menu','order']),
      image() {
        let image = 'https://storyblok.pleinaircdn.com/f/110020/62x62/7e05138e6f/placeholder.svg'
        if (this.menu) {
          this.menu.categories.forEach(category => {
            category.products.forEach(menuItem => {
              if (menuItem.name == this.item.name) {
                const imageSource = menuItem.images?.[1]?.filename
                if (imageSource) {
                  if (process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod') {
                    image = `https://olo-images-live.imgix.net/${imageSource}`
                  } else {
                    image = `https://olo-images-sandbox.imgix.net/${imageSource}`
                  }
                }
              }
            })
          })
        }
        return image
      },
      description() {
        if (this.item.choices && this.item.choices.length) {
          let desc = this.item.choices[0].name
          if (this.item.choices[0].quantity > 1) {
            desc = desc+' (x'+this.item.choices[0].quantity+')'
          }
          if (this.item.choices) {
            this.item.choices.forEach((choice, i) => {
              if (i > 0) {
                desc = desc+', '+choice.name
                if (choice.quantity > 1) {
                  desc = desc+' (x'+choice.quantity+')'
                }
              }
            })
          }
          if (this.item.instructions) {
            desc = desc+' ('+this.item.instructions+')'
          }
          return desc
        }
      },
    },
    methods: {
      ...mapActions([
        'decreaseQuantity',
        'increaseQuantity',
      ]),
      add(item) {
        const route = this.$route
        this.increaseQuantity({ item, route })
        console.log(item, 'item')
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'add_to_cart',
          'items': [this.item],
          'value': this.item.cost.total,
          'currency': 'USD'
        })
      },

      async remove() {
        await this.$api.removeFromOrder(this.item.id)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'remove_from_cart',
          'currency': 'USD',
          'value': this.item.cost.total,
          'items': this.item,
        })
        if (this.order.products.length > 0) {
          await this.$api.validateOrder()
        } else {
          this.$router.push('/order/')
          this.removePromo();
        }
      },
      edit() {
        let link = {}
        this.menu.categories.map((c) => {
          c.products.map((r) => {
            if (this.item.product_id === r.id) {
              link = {
                path: '/order/product',
                query: {
                  id: r.sku,
                  cid: this.item.id,
                  q: this.item.quantity
                }
              }
            }
          })
        })
        this.$router.push(link)
        if (this.$route.name == 'order-product') {
          setTimeout(() => {
            this.$router.go(link)
          },250)
        }
        this.$store.commit('setShowCart', false)
      },

      decreaseQuantityItem(item) {
        if (item) {
          this.decreaseQuantity(item)
          if (this.order &&  this.order.totals.total < 200) {
            this.removePromo()
          }
        }
      },

      async removePromo() {
        await this.$api.removeCoupon()
        this.promo = ''
        this.status = ''
      },
    }
  }
