
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        isAppCatering: false,
        appCateringRoutes: [ // routes to show the app catering footer on
          '/',
          '/menu',
          '/order',
          '/offers',
          '/account',
          '/account/settings',
          '/account/payments',
          '/account/order-history'
        ]
      }
    },
    computed: {
      ...mapState(['location','order','channel']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      showMobileNav() {
        return (
          (this.$mq == 'sm' || this.$mq == 'md') &&
          (
            this.$route.name !== 'order-product' &&
            this.$route.name !== 'order-checkout' &&
            this.$route.name !== 'order-items' &&
            this.$route.name !== 'account-login'
          )
        )
      },
      count() {
        if (this.order && this.order.products.length) {
          let count = 0
          this.order.products.forEach(product => {
            count += product.quantity
          })
          return count
        } else {
          return false
        }
      }
    },
    methods: {
      setPickup() {
        if (this.order && this.order.handoff == 'dispatch') {
          this.$api.setHandoff('pickup')
        } else {
          this.$router.push('/locations?mode=pickup')
        }
      },
      setDelivery() {
        this.$router.push('/locations?mode=delivery')
      },
    },
    beforeMount() {
      // Retrieve source cookie to determine if we're coming from app
      const cookies = document.cookie
        .split('; ')
        .reduce((acc, cookie) => {
          const [key, value] = cookie.split('=')
          acc[key] = value
          return acc
        }, {})
      if (cookies.source && cookies.source == 'app' && this.channel == 'catering') {
        this.isAppCatering = true
        this.$nextTick(() => {
          document.querySelectorAll('footer div:not(.mobile-nav):not(.app-catering-footer)').forEach(div => {
            div.style.display = 'none'
          })
        })
      }
    },
    // mounted() {
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       if (this.showMobileNav) {
    //         const recaptchaBadge = document.querySelector('.grecaptcha-badge')
    //         if (recaptchaBadge) {
    //           recaptchaBadge.style.bottom = '90px'
    //         }
    //       } else {
    //         const recaptchaBadge = document.querySelector('.grecaptcha-badge')
    //         if (recaptchaBadge) {
    //           recaptchaBadge.style.bottom = '14px'
    //         }
    //       }
    //     },500)
    //   })
    // },
    // watch: {
    //   showMobileNav(show) {
    //     if (show) {
    //       const recaptchaBadge = document.querySelector('.grecaptcha-badge')
    //       if (recaptchaBadge) {
    //         recaptchaBadge.style.bottom = '90px'
    //       }
    //     } else {
    //       const recaptchaBadge = document.querySelector('.grecaptcha-badge')
    //       if (recaptchaBadge) {
    //         recaptchaBadge.style.bottom = '14px'
    //       }
    //     }
    //   }
    // },
  }
