import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_4bfffd30 from 'nuxt_plugin_plugin_4bfffd30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_0b5cc3ee from 'nuxt_plugin_cookieuniversalnuxt_0b5cc3ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtmq_8bcb1064 from 'nuxt_plugin_nuxtmq_8bcb1064' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_workbox_3374283c from 'nuxt_plugin_workbox_3374283c' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_48ee30f5 from 'nuxt_plugin_metaplugin_48ee30f5' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_4d95d669 from 'nuxt_plugin_iconplugin_4d95d669' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_nuxtvuemultiselect_4b46e76e from 'nuxt_plugin_nuxtvuemultiselect_4b46e76e' // Source: ./nuxt-vue-multiselect.js (mode: 'all')
import nuxt_plugin_axios_525f1f74 from 'nuxt_plugin_axios_525f1f74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_templatesplugind431ce70_6e3f3f8d from 'nuxt_plugin_templatesplugind431ce70_6e3f3f8d' // Source: ./templates.plugin.d431ce70.js (mode: 'all')
import nuxt_plugin_dayjsplugin_403ea452 from 'nuxt_plugin_dayjsplugin_403ea452' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_nuxtplugin79ffe248_0323c6a2 from 'nuxt_plugin_nuxtplugin79ffe248_0323c6a2' // Source: ./nuxt.plugin.79ffe248.js (mode: 'all')
import nuxt_plugin_api_caeae0b4 from 'nuxt_plugin_api_caeae0b4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_jobs_92690f6c from 'nuxt_plugin_jobs_92690f6c' // Source: ../plugins/jobs (mode: 'all')
import nuxt_plugin_metadata_3ceffd23 from 'nuxt_plugin_metadata_3ceffd23' // Source: ../plugins/metadata (mode: 'all')
import nuxt_plugin_mixins_36a27306 from 'nuxt_plugin_mixins_36a27306' // Source: ../plugins/mixins (mode: 'all')
import nuxt_plugin_client_4d6b1c4c from 'nuxt_plugin_client_4d6b1c4c' // Source: ../plugins/client.client (mode: 'client')
import nuxt_plugin_global_2c8b7297 from 'nuxt_plugin_global_2c8b7297' // Source: ../plugins/global (mode: 'all')
import nuxt_plugin_storyblok_068762a7 from 'nuxt_plugin_storyblok_068762a7' // Source: ../plugins/storyblok (mode: 'all')
import nuxt_plugin_submit_417eaf0c from 'nuxt_plugin_submit_417eaf0c' // Source: ../plugins/submit (mode: 'all')
import nuxt_plugin_richtextrenderer_601869b4 from 'nuxt_plugin_richtextrenderer_601869b4' // Source: ../plugins/rich-text-renderer (mode: 'all')
import nuxt_plugin_storage_25489bf2 from 'nuxt_plugin_storage_25489bf2' // Source: ../plugins/storage (mode: 'server')
import nuxt_plugin_vuecountupv2_f715a64c from 'nuxt_plugin_vuecountupv2_f715a64c' // Source: ../plugins/vue-countup-v2 (mode: 'client')
import nuxt_plugin_vuevideobackground_0ba2e1ca from 'nuxt_plugin_vuevideobackground_0ba2e1ca' // Source: ../plugins/vue-video-background (mode: 'client')
import nuxt_plugin_smoothscrollpolyfill_1225bd6d from 'nuxt_plugin_smoothscrollpolyfill_1225bd6d' // Source: ../plugins/smoothscroll-polyfill (mode: 'client')
import nuxt_plugin_googlemaps_c54ce192 from 'nuxt_plugin_googlemaps_c54ce192' // Source: ../plugins/google-maps (mode: 'client')
import nuxt_plugin_vuegoogleoauth2_459c9714 from 'nuxt_plugin_vuegoogleoauth2_459c9714' // Source: ../plugins/vue-google-oauth2 (mode: 'client')
import nuxt_plugin_gtm_caeab2a8 from 'nuxt_plugin_gtm_caeab2a8' // Source: ../plugins/gtm (mode: 'client')
import nuxt_plugin_vueanimateonscroll_4782bc33 from 'nuxt_plugin_vueanimateonscroll_4782bc33' // Source: ../plugins/vue-animate-onscroll (mode: 'client')
import nuxt_plugin_bugsnag_6b5683c1 from 'nuxt_plugin_bugsnag_6b5683c1' // Source: ../plugins/bugsnag (mode: 'client')
import nuxt_plugin_braze_22342f88 from 'nuxt_plugin_braze_22342f88' // Source: ../plugins/braze (mode: 'client')
import nuxt_plugin_libnuxtclientinitpluginclient6c314a91_10c33b47 from 'nuxt_plugin_libnuxtclientinitpluginclient6c314a91_10c33b47' // Source: ./lib.nuxt-client-init.plugin.client.6c314a91.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s | Bojangles","title":"Bojangles","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Bojangles"},{"name":"facebook-domain-verification","content":"hbzoqw1q0fgr0plpgpbiuyfb6iblmf"},{"name":"theme-color","content":"#FFF"},{"hid":"ahrefs-site-verification","name":"ahrefs-site-verification","content":"4e194f940a216533b7de430257863c172a4ac591cfccb5eb620582eabe1d516e"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002F\u002Fstoryblok.pleinaircdn.com\u002Ff\u002F110020\u002Fx\u002F465a08757f\u002Ffavicon.svg"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Roboto&display=swap"},{"rel":"stylesheet","type":"text\u002Fcss","href":"\u002F\u002Fassets.sitescdn.net\u002Fanswers-search-bar\u002Fv1.2\u002Fanswers.css"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fassets.sitescdn.net\u002Fanswers-search-bar\u002Fv1.5\u002Fanswers.css"}],"script":[{"src":"https:\u002F\u002Fstatic.olocdn.net\u002Fweb-client\u002Fcheckout-web-client\u002F2.5.0\u002Fcheckout.js"},{"src":"https:\u002F\u002Fappleid.cdn-apple.com\u002Fappleauth\u002Fstatic\u002Fjsapi\u002Fappleid\u002F1\u002Fen_US\u002Fappleid.auth.js"},{"src":"\u002F\u002Fassets.sitescdn.net\u002Fanswers\u002Fv1\u002Fanswers.min.js"},{"src":"https:\u002F\u002Fassets.sitescdn.net\u002Fanswers-search-bar\u002Fv1.5\u002Fanswerstemplates.compiled.min.js"},{"src":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Ftyped.js@2.0.11"},{"src":"https:\u002F\u002Fjs.appboycdn.com\u002Fweb-sdk\u002F5.1\u002Fservice-worker.js"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_4bfffd30 === 'function') {
    await nuxt_plugin_plugin_4bfffd30(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_0b5cc3ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_0b5cc3ee(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_8bcb1064 === 'function') {
    await nuxt_plugin_nuxtmq_8bcb1064(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_3374283c === 'function') {
    await nuxt_plugin_workbox_3374283c(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_48ee30f5 === 'function') {
    await nuxt_plugin_metaplugin_48ee30f5(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_4d95d669 === 'function') {
    await nuxt_plugin_iconplugin_4d95d669(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtvuemultiselect_4b46e76e === 'function') {
    await nuxt_plugin_nuxtvuemultiselect_4b46e76e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_525f1f74 === 'function') {
    await nuxt_plugin_axios_525f1f74(app.context, inject)
  }

  if (typeof nuxt_plugin_templatesplugind431ce70_6e3f3f8d === 'function') {
    await nuxt_plugin_templatesplugind431ce70_6e3f3f8d(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_403ea452 === 'function') {
    await nuxt_plugin_dayjsplugin_403ea452(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin79ffe248_0323c6a2 === 'function') {
    await nuxt_plugin_nuxtplugin79ffe248_0323c6a2(app.context, inject)
  }

  if (typeof nuxt_plugin_api_caeae0b4 === 'function') {
    await nuxt_plugin_api_caeae0b4(app.context, inject)
  }

  if (typeof nuxt_plugin_jobs_92690f6c === 'function') {
    await nuxt_plugin_jobs_92690f6c(app.context, inject)
  }

  if (typeof nuxt_plugin_metadata_3ceffd23 === 'function') {
    await nuxt_plugin_metadata_3ceffd23(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_36a27306 === 'function') {
    await nuxt_plugin_mixins_36a27306(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_client_4d6b1c4c === 'function') {
    await nuxt_plugin_client_4d6b1c4c(app.context, inject)
  }

  if (typeof nuxt_plugin_global_2c8b7297 === 'function') {
    await nuxt_plugin_global_2c8b7297(app.context, inject)
  }

  if (typeof nuxt_plugin_storyblok_068762a7 === 'function') {
    await nuxt_plugin_storyblok_068762a7(app.context, inject)
  }

  if (typeof nuxt_plugin_submit_417eaf0c === 'function') {
    await nuxt_plugin_submit_417eaf0c(app.context, inject)
  }

  if (typeof nuxt_plugin_richtextrenderer_601869b4 === 'function') {
    await nuxt_plugin_richtextrenderer_601869b4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storage_25489bf2 === 'function') {
    await nuxt_plugin_storage_25489bf2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecountupv2_f715a64c === 'function') {
    await nuxt_plugin_vuecountupv2_f715a64c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuevideobackground_0ba2e1ca === 'function') {
    await nuxt_plugin_vuevideobackground_0ba2e1ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smoothscrollpolyfill_1225bd6d === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_1225bd6d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlemaps_c54ce192 === 'function') {
    await nuxt_plugin_googlemaps_c54ce192(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegoogleoauth2_459c9714 === 'function') {
    await nuxt_plugin_vuegoogleoauth2_459c9714(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_caeab2a8 === 'function') {
    await nuxt_plugin_gtm_caeab2a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueanimateonscroll_4782bc33 === 'function') {
    await nuxt_plugin_vueanimateonscroll_4782bc33(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bugsnag_6b5683c1 === 'function') {
    await nuxt_plugin_bugsnag_6b5683c1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_braze_22342f88 === 'function') {
    await nuxt_plugin_braze_22342f88(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclient6c314a91_10c33b47 === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclient6c314a91_10c33b47(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
