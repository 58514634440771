import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=b9953de0&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=b9953de0&prod&lang=scss&scoped=true&"
import style1 from "./Footer.vue?vue&type=style&index=1&id=b9953de0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9953de0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMarker: require('/opt/build/repo/components/icons/IconMarker.vue').default,IconChevronDown: require('/opt/build/repo/components/icons/IconChevronDown.vue').default,IconCateringHome: require('/opt/build/repo/components/icons/IconCateringHome.vue').default,IconCateringMenu: require('/opt/build/repo/components/icons/IconCateringMenu.vue').default,IconCateringOffers: require('/opt/build/repo/components/icons/IconCateringOffers.vue').default,IconCateringUser: require('/opt/build/repo/components/icons/IconCateringUser.vue').default,IconCateringCart: require('/opt/build/repo/components/icons/IconCateringCart.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default})
